import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const AboutUsPage = () => {
	return (
		<Layout>
			<SEO title="Rólunk" />
			<div className="container px-4" id="kik-vagyunk">
				<h1 className="pt-12 text-2xl leading-tight font-semibold">Kik vagyunk?</h1>
				<p className="my-8">
					A mostvasarnap.hu mögött egy civil csoport áll. A magánszektorban dolgozunk: napi munkánk és családi
					kötelezettségeink mellett, társadalmi felelősségvállalásunk részeként fejlesztjük a honlapot és
					készítjük az elemzéseket. Vannak köztünk többek között informatikusok, adatelemzők, matematikusok,
					térképészek és újságírók. Barátaink és családtagjaink is sokat segítenek.
				</p>
			</div>
			<div className="container px-4" id="celjaink">
				<h1 className="pt-12 text-2xl leading-tight font-semibold">Céljaink</h1>
				<p className="my-8">
					Fő célunk a magyar választási rendszer és választási földrajz bemutatása közérthető módon. Egyedi
					módon az elemzések publikálásán túl az Adattárban a felhasznált adatokat is közzétesszük a
					nyilvánosság számára, hogy ezzel segítsük minden választási elemző munkáját.
				</p>
			</div>
			<div className="container px-4" id="tamogatas">
				<h1 className="pt-12 text-2xl leading-tight font-semibold">Támogatás</h1>
				<p className="my-8">
					Jelenleg nem szorulunk pénzbeli támogatásra, de ha Brave böngészőt használ, akkor a látogatásaival
					vagy "Send tip" formában tud minket támogatni BAT tokennel. Részletek:{" "}
					<a className="link" href="https://brave.com/brave-rewards">
						Brave rewards
					</a>{" "}
				</p>
			</div>
			<div className="container px-4" id="csatlakozas">
				<h1 className="pt-12 text-2xl leading-tight font-semibold">Csatlakozás</h1>
				<p className="my-8">
					Amennyiben érdekel a munkánk és szívesen csatlakoznál, keress meg minket az{" "}
					<a className="link" href="mailto:info@mostvasarnap.hu">
						info@mostvasarnap.hu
					</a>{" "}
					email címen. Mindenképpen írd meg hogy milyen területeken tudnál segíteni és küldj szakmai
					életrajzot.
				</p>
			</div>
		</Layout>
	);
};

export default AboutUsPage;
